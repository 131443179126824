<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Pagos</span>
          </v-col>
          <v-col cols="12" md="3"> </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <br />
        <v-card elevation="4">
          <p class="pt-5 text-subtitle-2 text-center">Pagos de servicios</p>
          <v-data-table :headers="headers" :items="payments" :search="search" :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
            
            <template v-slot:item.legal_name="{ item }">
              <span>{{ getNameCustomer(item.id_customer) }}</span>
            </template>
            <template v-slot:item.name_plan="{ item }">
              <span>{{ getNamePlanByIdContract(item.id_contract) }}</span>
            </template>
            <template v-slot:item.name_service="{ item }">
              <span>{{ getNameService(item.id_contract) }}</span>
            </template> <template v-slot:item.state="{ item }">
              <span v-if="item.url_file == ''">Pendiente</span>
              <span v-if="item.url_file != ''">Aceptado</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top v-if="item.url_file != null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :href="'https://api.sipymex.cl/' + item.url_file" >
                    <v-icon v-bind="attrs" color="success" v-on="on">
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver Factura</span>
              </v-tooltip>

              <v-tooltip top v-if="item.url_file == null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed
                     disabled icon :href="'https://api.sipymex.cl/' + item.url_file" >
                    <v-icon v-bind="attrs" color="grey" v-on="on">
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Factura no generada</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <br />
        <v-card elevation="4">
          <p class="pt-5 text-subtitle-2 text-center">Pagos de equipos</p>
          <v-data-table :headers="headersEquipment" :items="paymentEquipments" :search="search" :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }" no-data-text="No hay datos disponibles">
            <template v-slot:item.name_equipment="{ item }">
              <span>{{ getNameEquipment(item.id_equipment) }}</span>
            </template>
            <template v-slot:item.legal_name="{ item }">
              <span>{{ getNameCustomer(item.id_customer) }}</span>
            </template>
            <template v-slot:item.state="{ item }">
              <span v-if="item.url_file == ''">Pendiente</span>
              <span v-if="item.url_file != ''">Aceptado</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top v-if="item.url_file != null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :href="'https://api.sipymex.cl/' + item.url_file" >
                    <v-icon v-bind="attrs" color="success" v-on="on">
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver Factura</span>
              </v-tooltip>

              <v-tooltip top v-if="item.url_file == null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed
                     disabled icon :href="'https://api.sipymex.cl/' + item.url_file" >
                    <v-icon v-bind="attrs" color="grey" v-on="on">
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Factura no generada</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  data: () => ({
    dialog: false,
    search: "",
    headers: [
      { text: "Empresa", value: "legal_name" },
      { text: "Servicio", value: "name_service" },
      { text: "Plan", value: "name_plan" },
      { text: "Fecha de Pago", value: "date" },
      { text: "Monto total", value: "price" },
      { text: "Acciones", value: "actions" },
    ],
    headersEquipment: [
      { text: "Empresa", value: "legal_name" },
      { text: "Equipo", value: "name_equipment" },
      { text: "Fecha de Pago", value: "date" },
      { text: "Monto total", value: "price" },
      { text: "Estado", value: "state" },
      { text: "Acciones", value: "actions" },
    ],
    payments: [],
    paymentEquipments: [],
    customers: [],
    equipments: [],
    services: [],
    plans: [],
    contracts: [],
    id_payment: "",
    id_customer: "",
    id_service: "",
    id_plan: "",
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getPayments();
    this.getPlans();
    this.getCustomers();
    this.getContracts();
    this.getEquipments();
    this.getServices();
  },
  watch: {},
  methods: {


    getPayments() {
      this.getPaymentsService();
      this.getPaymentsEquipments();
    },
    getEquipments() {
      axios
        .get("/get-equipment")
        .then((response) => {
          console.log("Obteniendo Pagos");
          console.log(response.data);
          this.equipments = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener equipos"
          );
        });
    },
    getPaymentsService() {
      axios
        .get("/payments")
        .then((response) => {
          console.log("Obteniendo Pagos");
          console.log(response.data);
          this.payments = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener pagos");
        });
    },
    getPaymentsEquipments() {
      axios
        .get("/get-payments-equipments")
        .then((response) => {
          console.log("Obteniendo Pagos equipos");
          console.log(response.data);
          this.paymentEquipments = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener pagos");
        });
    },
    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log("Obteniedo planes");
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },
    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log("Obtniendo clientes");
          console.log(response.data);
          this.customers = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener clientes"
          );
        });
    },
    getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log("Obteniendo servicios");
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener servicios"
          );
        });
    },
    getContracts() {
      axios
        .get("/contracts")
        .then((response) => {
          console.log("Obteniendo contratos");
          console.log(response.data);
          this.contracts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener contratos"
          );
        });
    },
    getContractById(id_contract) {
      let contract = this.contracts.find((c) => c.id === id_contract);
      return contract;
    },
    getNameCustomerByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getNameCustomer(contract.id_customer);
      return name;
    },
    getNameCustomer(id_customer) {
      //  this.getServices();
      let customer = this.customers.find(
        (customer) => customer.id === id_customer
      );
      return customer.legal_name;
    },
    getNameEquipment(id_equipment) {
      //  this.getServices();
      let equipment = this.equipments.find(
        (equipment) => equipment.id === id_equipment
      );
      return equipment.name;
    },

    getNamePlanByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getNamePlan(contract.id_plan);
      return name;
    },
    getNameService(id_contract) {
      let contract = this.getContractById(id_contract);
      let plan = this.getPlan(contract.id_plan);
      let service = this.getService(plan.id_service);
      return service;
    },
    getPlan(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);
      return plan;
    },

    getNamePlan(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);
      return plan.name;
    },
    getService(id_service) {
      //  this.getServices();
      let service = this.services.find((service) => service.id === id_service);
      return service.name;
    },

    getDayBillingPeriodsByIdContract(id_contract) {
      let contract = this.getContractById(id_contract);
      let name = this.getDayBillingPeriod(contract.id_billing_periods);

      console.log("getDayBillingPeriodsByIdContract");
      console.log("contract.id_billing_periods ");
      console.log(name);
      return name;
    },
  },
};
</script>