var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('vue-snotify'),_c('v-card',[_c('v-card-title',{staticClass:"cyan darken-1 card-sipymex"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',{staticClass:"white--text"},[_vm._v("Pagos")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}})],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('br'),_c('v-card',{attrs:{"elevation":"4"}},[_c('p',{staticClass:"pt-5 text-subtitle-2 text-center"},[_vm._v("Pagos de servicios")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.payments,"search":_vm.search,"footer-props":{
          itemsPerPageText: 'Filas por página',
          itemsPerPageAllText: 'todos',
        },"no-data-text":"No hay datos disponibles","no-results-text":"No hay datos disponibles"},scopedSlots:_vm._u([{key:"item.legal_name",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getNameCustomer(item.id_customer)))])]}},{key:"item.name_plan",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getNamePlanByIdContract(item.id_contract)))])]}},{key:"item.name_service",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getNameService(item.id_contract)))])]}},{key:"item.state",fn:function(ref){
        var item = ref.item;
return [(item.url_file == '')?_c('span',[_vm._v("Pendiente")]):_vm._e(),(item.url_file != '')?_c('span',[_vm._v("Aceptado")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.url_file != null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","href":'https://api.sipymex.cl/' + item.url_file}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Factura")])]):_vm._e(),(item.url_file == null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","disabled":"","icon":"","href":'https://api.sipymex.cl/' + item.url_file}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Factura no generada")])]):_vm._e()]}}])})],1),_c('br'),_c('v-card',{attrs:{"elevation":"4"}},[_c('p',{staticClass:"pt-5 text-subtitle-2 text-center"},[_vm._v("Pagos de equipos")]),_c('v-data-table',{attrs:{"headers":_vm.headersEquipment,"items":_vm.paymentEquipments,"search":_vm.search,"footer-props":{
          itemsPerPageText: 'Filas por página',
          itemsPerPageAllText: 'todos',
        },"no-data-text":"No hay datos disponibles"},scopedSlots:_vm._u([{key:"item.name_equipment",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getNameEquipment(item.id_equipment)))])]}},{key:"item.legal_name",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getNameCustomer(item.id_customer)))])]}},{key:"item.state",fn:function(ref){
        var item = ref.item;
return [(item.url_file == '')?_c('span',[_vm._v("Pendiente")]):_vm._e(),(item.url_file != '')?_c('span',[_vm._v("Aceptado")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.url_file != null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","href":'https://api.sipymex.cl/' + item.url_file}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Factura")])]):_vm._e(),(item.url_file == null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"depressed":"","disabled":"","icon":"","href":'https://api.sipymex.cl/' + item.url_file}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Factura no generada")])]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }